#contact ul {
    /* border: solid yellow 1px; */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

#contact ul li {
    margin-right: 4em;
}
#contact ul li:last-child {
    margin-right: 2em
}

#contact ul li a {
    /* border: solid red 1px; */
    width: 7em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    width:  fit-content;
    font-size: 1.3em;
    line-height: 3em;
    color: var(--color-text);
}

/* footer icon */
#contact ul li a svg {
    color: var(--color-theme);
}
@import url(https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@300&display=swap);
.custom-section {
  position: relative;
  border: solid var(--color-theme-lighter) 1px;
  border-radius: var(--border-radius);
  z-index: 1;
}

#blur-box {
  background-color: var(--color-background);
  /* Add the blur effect */
  filter: blur(2px);
  -webkit-filter: blur(8px);
  position: absolute;

  width: 100%;
  height: 100%;

  z-index: -2;
}

section.about p {
  /* text-indent: 2em; */
  /* text-align: center; */
  text-align:justify
}

.portrait {
  display: block;
  margin: 0 1em 0;
  float: left;
  width: 200px;
  border-radius: var(--border-radius);
}

@media screen and (max-width: 480px) {
  section.about {
    display: flex;
    flex-direction: column;
  }
  section.about p {
    margin-left: 1em;
  }

  .portrait {
    float: none;
    margin: 0 auto 2em auto;
  }
}

.section-header h3 {
    color: var(--color-text-lighter);
}
.carousel .carousel-item {
  overflow-x: scroll;
}

.carousel .carousel-item > img {
  border-radius: var(--border-radius);
}
.carousel .carousel-item.active > img {
  border: solid var(--color-text-lighter) 2px !important;
}

.carousel .indicators .indicator-item {
  margin: 0 0.5em;
  background-color: var(--color-theme-lighter);
}
.carousel .indicators .indicator-item.active {
  background-color: var(--color-theme);
}

/* see stling applied from sidenav buttons */
/* "./src/components/Nav/style.css" */

.btn-custom {
  padding: .2em .7em;
}

.btn-custom,
.sidenav li>a {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    
    margin: 1em;
    border: solid .1rem var(--color-theme);
    border-radius: var(--border-radius);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    text-align: center;
    color: var(--color-text);
  
    box-shadow: 
      0 2px 2px 0 var(--color-theme-lighter),
      0 3px 1px -2px var(--color-theme-lighter),
      0 1px 5px 0 var(--color-theme-lighter);
}

.btn-custom:hover,
.sidenav li>a:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
.project-links {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
}

.project-links svg{
    color: var(--color-theme);
}
.shield {
    margin: .25rem;
    border:1px solid rgba(0, 0, 0, 0.5) ;
    border-radius: var(--border-radius);
  }
  
  .shield:hover {
    border:3px solid rgba(0, 0, 0, 0) ;
    border-radius: var(--border-radius);
  }
.project-technologies {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.project-title {
  text-align: center;
}

#current-project.carousel {
  height: 520px;
}
#current-project.carousel .carousel-item {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

#previous-project.carousel {
  height: 500px;
}

#previous-project.carousel .carousel-item {
  height: 440px;
}


.list-of-items {
  margin: 0 2em;
  min-height: 200px;
}

@media screen and (max-width: 480px) {
  .list-of-items {
    margin: 0 auto;
  }
}

section.skills {
  display: flex;
}

.skill:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

@media screen and (max-width: 480px) {
  section.skills {
    flex-direction: column;
    margin-top: 0;
  }
}

img#background {
  position: fixed;
  top: 0;

  height: 100%;
  width: 100%;
  z-index: -1;
}

#contact ul {
    /* border: solid yellow 1px; */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

#contact ul li {
    margin-right: 4em;
}
#contact ul li:last-child {
    margin-right: 2em
}

#contact ul li a {
    /* border: solid red 1px; */
    width: 7em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    width:  -webkit-fit-content;
    
    width:  -moz-fit-content;
    
    width:  fit-content;
    font-size: 1.3em;
    line-height: 3em;
    color: var(--color-text);
}

/* footer icon */
#contact ul li a svg {
    color: var(--color-theme);
}
/* overwrite materialize */
.page-footer {
  background-color: var(--color-none);
  display: flex;
  justify-content: flex-end;
}

footer h6 {
  margin: 1em 0;
  color: var(--color-text-lightest);
}

footer section.custom-section {
  margin-bottom: 0;
  border-right: none;
  border-bottom: none;
  border-left: none;
  border-radius: 0;
}

nav,
nav.nav-wrapper {
  position: relative;
  display: inline-block;
  background-color: var(--color-background);

  /* background: -webkit-linear-gradient(
    left,
    var(--color-nav-lighter) 0%,
    var(--color-nav-lighter) 40%,
    var(--color-nav-lighter) 60%,
    var(--color-nav-lighter) 100%
  ); */
  border-bottom: solid var(--color-theme-lighter) 1px;
}

/* nav name position from left of screen, remains centeres on mobile */
.brand-logo {
  left: 0.5em;
}

/* poaition menu icon */
nav .sidenav-trigger {
  float: right;
  margin: 0 0 0 0;
}

/* overwrite materialize-css */
.sidenav {
  /* border: solid red 1px; */
  top: 64px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;

  background: -webkit-linear-gradient(
    236deg,
    var(--color-background) 0%,
    var(--color-none) 100%
  );

  -webkit-backdrop-filter: blur(5px);

          backdrop-filter: blur(5px);
  box-shadow: none;
}

/* align button contents to right */
.sidenav li > a.sidenav-close {
  justify-content: flex-end;
}

/* space between button text and icon */
.sidenav-close svg {
  margin-left: 0.5em;
  color: var(--color-theme);
}

/* overwrite materialize-css */
#mobile-nav {
  margin-top: 0;
}

/* adjust sidenav position on tablet/mobile devices */
@media screen and (max-width: 600px) {
  .sidenav {
    top: 54px;
  }
}


/* materialize preloader container */
.preloader {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
:root {
  --border-radius: 4px;
  --border-radius-project-img: 20px;

  --color-text: rgba(255, 250, 250, 0.788);
  --color-text-lighter: rgba(243, 239, 239, 0.541);
  --color-text-lightest: rgba(243, 239, 239, 0.349);

  --color-nav: rgb(58, 67, 78);
  --color-nav-lighter: rgba(58, 67, 78, 0.438);

  --color-none: rgba(0, 0, 0, 0);

  --color-theme: rgba(112, 255, 107, 0.726);
  --color-theme-lighter: rgba(112, 255, 107, 0.322);

  --color-background: rgba(0, 0, 0, 0.705);
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

body {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 1rem;
  line-height: 1.75rem;
  letter-spacing: 0.05rem;

  color: rgba(255, 250, 250, 0.788);

  color: var(--color-text);
}

.border {
  border: solid rgba(255, 250, 250, 0.788) 1px;
  border: solid var(--color-text) 1px;
  border-radius: 4px;
  border-radius: var(--border-radius);
}

/* materialize modification*/
.divider {
  background-color: rgba(112, 255, 107, 0.322);
  background-color: var(--color-theme-lighter);
}

/* BUTTON & LINK BEHAVIOR ========================================== */

.hover:hover,
.contact-list-anchor:hover {
  cursor: pointer;
  color: var(--theme1);
  border-bottom: solid;
}

/* removes blue line underneath a links */
a:-webkit-any-link {
  text-decoration: none;
}

/* @media screen and (max-width: 800px) {
} */

/* center text on mobile devices */
@media screen and (max-width: 480px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: center;
  }
}

/* spacing helpers */
.p-0 {
  padding: 0 0 0 0;
}
.p-05 {
  padding: 0.5em 0.5em 0.5em 0.5em;
}
.p-1 {
  padding: 1em 1em 1em 1em;
}
.p-2 {
  padding: 2em 2em 2em 2em;
}
.p-3 {
  padding: 3em 3em 3em 3em;
}
.pt-0 {
  padding-top: 0;
}
.pt-1 {
  padding-top: 1em;
}
.pt-2 {
  padding-top: 2em;
}
.pt-3 {
  padding-top: 3em;
}
.pl-0 {
  padding-left: 0;
}
.pl-1 {
  padding-left: 1em;
}
.pl-2 {
  padding-left: 2em;
}
.pl-3 {
  padding-left: 3em;
}
.pb-0 {
  padding-bottom: 0;
}
.pb-1 {
  padding-bottom: 1em;
}
.pb-2 {
  padding-bottom: 2em;
}
.pb-3 {
  padding-bottom: 3em;
}
.pr-0 {
  padding-right: 0;
}
.pr-1 {
  padding-right: 1em;
}
.pr-2 {
  padding-right: 2em;
}
.pr-3 {
  padding-right: 3em;
}
.px-0 {
  padding-right: 0;
  padding-left: 0;
}
.px-1 {
  padding-right: 1em;
  padding-left: 1em;
}
.px-2 {
  padding-right: 2em;
  padding-left: 2em;
}
.px-3 {
  padding-right: 3em;
  padding-left: 3em;
}
.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1 {
  padding-top: 1em;
  padding-bottom: 1em;
}
.py-2 {
  padding-top: 2em;
  padding-bottom: 2em;
}
.py-3 {
  padding-top: 3em;
  padding-bottom: 3em;
}

.m-0 {
  margin: 0 0 0 0;
}
.m-1 {
  margin: 1em 1em 1em 1em;
}
.m-2 {
  margin: 2em 2em 2em 2em;
}
.m-3 {
  margin: 3em 3em 3em 3em;
}
.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: 1em;
}
.mt-2 {
  margin-top: 2em;
}
.mt-3 {
  margin-top: 3em;
}
.ml-0 {
  margin-left: 0;
}
.ml-1 {
  margin-left: 1em;
}
.ml-2 {
  margin-left: 2em;
}
.ml-3 {
  margin-left: 3em;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 1em;
}
.mb-2 {
  margin-bottom: 2em;
}
.mb-3 {
  margin-bottom: 3em;
}
.mr-0 {
  margin-right: 0;
}
.mr-1 {
  margin-right: 1em;
}
.mr-2 {
  margin-right: 2em;
}
.mr-3 {
  margin-right: 3em;
}

.mx-0 {
  margin-right: 0;
  margin-left: 0;
}
.mx-1 {
  margin-right: 1em;
  margin-left: 1em;
}
.mx-2 {
  margin-right: 2em;
  margin-left: 2em;
}
.mx-3 {
  margin-right: 3em;
  margin-left: 3em;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.my-1 {
  margin-top: 1em;
  margin-bottom: 1em;
}
.my-2 {
  margin-top: 2em;
  margin-bottom: 2em;
}
.my-3 {
  margin-top: 3em;
  margin-bottom: 3em;
}


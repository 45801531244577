/* see stling applied from sidenav buttons */
/* "./src/components/Nav/style.css" */

.btn-custom {
  padding: .2em .7em;
}

.btn-custom,
.sidenav li>a {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    
    margin: 1em;
    border: solid .1rem var(--color-theme);
    border-radius: var(--border-radius);
    backdrop-filter: blur(5px);
    text-align: center;
    color: var(--color-text);
  
    box-shadow: 
      0 2px 2px 0 var(--color-theme-lighter),
      0 3px 1px -2px var(--color-theme-lighter),
      0 1px 5px 0 var(--color-theme-lighter);
}

.btn-custom:hover,
.sidenav li>a:hover {
    transform: scale(1.05);
  }
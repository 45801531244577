section.skills {
  display: flex;
}

.skill:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 480px) {
  section.skills {
    flex-direction: column;
    margin-top: 0;
  }
}

#blur-box {
  background-color: var(--color-background);
  /* Add the blur effect */
  filter: blur(2px);
  -webkit-filter: blur(8px);
  position: absolute;

  width: 100%;
  height: 100%;

  z-index: -2;
}

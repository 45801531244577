section.about p {
  /* text-indent: 2em; */
  /* text-align: center; */
  text-align:justify
}

.portrait {
  display: block;
  margin: 0 1em 0;
  float: left;
  width: 200px;
  border-radius: var(--border-radius);
}

@media screen and (max-width: 480px) {
  section.about {
    display: flex;
    flex-direction: column;
  }
  section.about p {
    margin-left: 1em;
  }

  .portrait {
    float: none;
    margin: 0 auto 2em auto;
  }
}

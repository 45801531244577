nav,
nav.nav-wrapper {
  position: relative;
  display: inline-block;
  background-color: var(--color-background);

  /* background: -webkit-linear-gradient(
    left,
    var(--color-nav-lighter) 0%,
    var(--color-nav-lighter) 40%,
    var(--color-nav-lighter) 60%,
    var(--color-nav-lighter) 100%
  ); */
  border-bottom: solid var(--color-theme-lighter) 1px;
}

/* nav name position from left of screen, remains centeres on mobile */
.brand-logo {
  left: 0.5em;
}

/* poaition menu icon */
nav .sidenav-trigger {
  float: right;
  margin: 0 0 0 0;
}

/* overwrite materialize-css */
.sidenav {
  /* border: solid red 1px; */
  top: 64px;
  width: fit-content;

  background: -webkit-linear-gradient(
    236deg,
    var(--color-background) 0%,
    var(--color-none) 100%
  );

  backdrop-filter: blur(5px);
  box-shadow: none;
}

/* align button contents to right */
.sidenav li > a.sidenav-close {
  justify-content: flex-end;
}

/* space between button text and icon */
.sidenav-close svg {
  margin-left: 0.5em;
  color: var(--color-theme);
}

/* overwrite materialize-css */
#mobile-nav {
  margin-top: 0;
}

/* adjust sidenav position on tablet/mobile devices */
@media screen and (max-width: 600px) {
  .sidenav {
    top: 54px;
  }
}

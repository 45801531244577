@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@300&display=swap");

:root {
  --border-radius: 4px;
  --border-radius-project-img: 20px;

  --color-text: rgba(255, 250, 250, 0.788);
  --color-text-lighter: rgba(243, 239, 239, 0.541);
  --color-text-lightest: rgba(243, 239, 239, 0.349);

  --color-nav: rgb(58, 67, 78);
  --color-nav-lighter: rgba(58, 67, 78, 0.438);

  --color-none: rgba(0, 0, 0, 0);

  --color-theme: rgba(112, 255, 107, 0.726);
  --color-theme-lighter: rgba(112, 255, 107, 0.322);

  --color-background: rgba(0, 0, 0, 0.705);
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

body {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 1rem;
  line-height: 1.75rem;
  letter-spacing: 0.05rem;

  color: var(--color-text);
}

.border {
  border: solid var(--color-text) 1px;
  border-radius: var(--border-radius);
}

/* materialize modification*/
.divider {
  background-color: var(--color-theme-lighter);
}

/* BUTTON & LINK BEHAVIOR ========================================== */

.hover:hover,
.contact-list-anchor:hover {
  cursor: pointer;
  color: var(--theme1);
  border-bottom: solid;
}

/* removes blue line underneath a links */
a:-webkit-any-link {
  text-decoration: none;
}

/* @media screen and (max-width: 800px) {
} */

/* center text on mobile devices */
@media screen and (max-width: 480px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: center;
  }
}

/* spacing helpers */
.p-0 {
  padding: 0 0 0 0;
}
.p-05 {
  padding: 0.5em 0.5em 0.5em 0.5em;
}
.p-1 {
  padding: 1em 1em 1em 1em;
}
.p-2 {
  padding: 2em 2em 2em 2em;
}
.p-3 {
  padding: 3em 3em 3em 3em;
}
.pt-0 {
  padding-top: 0;
}
.pt-1 {
  padding-top: 1em;
}
.pt-2 {
  padding-top: 2em;
}
.pt-3 {
  padding-top: 3em;
}
.pl-0 {
  padding-left: 0;
}
.pl-1 {
  padding-left: 1em;
}
.pl-2 {
  padding-left: 2em;
}
.pl-3 {
  padding-left: 3em;
}
.pb-0 {
  padding-bottom: 0;
}
.pb-1 {
  padding-bottom: 1em;
}
.pb-2 {
  padding-bottom: 2em;
}
.pb-3 {
  padding-bottom: 3em;
}
.pr-0 {
  padding-right: 0;
}
.pr-1 {
  padding-right: 1em;
}
.pr-2 {
  padding-right: 2em;
}
.pr-3 {
  padding-right: 3em;
}
.px-0 {
  padding-right: 0;
  padding-left: 0;
}
.px-1 {
  padding-right: 1em;
  padding-left: 1em;
}
.px-2 {
  padding-right: 2em;
  padding-left: 2em;
}
.px-3 {
  padding-right: 3em;
  padding-left: 3em;
}
.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1 {
  padding-top: 1em;
  padding-bottom: 1em;
}
.py-2 {
  padding-top: 2em;
  padding-bottom: 2em;
}
.py-3 {
  padding-top: 3em;
  padding-bottom: 3em;
}

.m-0 {
  margin: 0 0 0 0;
}
.m-1 {
  margin: 1em 1em 1em 1em;
}
.m-2 {
  margin: 2em 2em 2em 2em;
}
.m-3 {
  margin: 3em 3em 3em 3em;
}
.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: 1em;
}
.mt-2 {
  margin-top: 2em;
}
.mt-3 {
  margin-top: 3em;
}
.ml-0 {
  margin-left: 0;
}
.ml-1 {
  margin-left: 1em;
}
.ml-2 {
  margin-left: 2em;
}
.ml-3 {
  margin-left: 3em;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 1em;
}
.mb-2 {
  margin-bottom: 2em;
}
.mb-3 {
  margin-bottom: 3em;
}
.mr-0 {
  margin-right: 0;
}
.mr-1 {
  margin-right: 1em;
}
.mr-2 {
  margin-right: 2em;
}
.mr-3 {
  margin-right: 3em;
}

.mx-0 {
  margin-right: 0;
  margin-left: 0;
}
.mx-1 {
  margin-right: 1em;
  margin-left: 1em;
}
.mx-2 {
  margin-right: 2em;
  margin-left: 2em;
}
.mx-3 {
  margin-right: 3em;
  margin-left: 3em;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.my-1 {
  margin-top: 1em;
  margin-bottom: 1em;
}
.my-2 {
  margin-top: 2em;
  margin-bottom: 2em;
}
.my-3 {
  margin-top: 3em;
  margin-bottom: 3em;
}

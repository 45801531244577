.list-of-items {
  margin: 0 2em;
  min-height: 200px;
}

@media screen and (max-width: 480px) {
  .list-of-items {
    margin: 0 auto;
  }
}

/* overwrite materialize */
.page-footer {
  background-color: var(--color-none);
  display: flex;
  justify-content: flex-end;
}

footer h6 {
  margin: 1em 0;
  color: var(--color-text-lightest);
}

footer section.custom-section {
  margin-bottom: 0;
  border-right: none;
  border-bottom: none;
  border-left: none;
  border-radius: 0;
}

.carousel .carousel-item {
  overflow-x: scroll;
}

.carousel .carousel-item > img {
  border-radius: var(--border-radius);
}
.carousel .carousel-item.active > img {
  border: solid var(--color-text-lighter) 2px !important;
}

.carousel .indicators .indicator-item {
  margin: 0 0.5em;
  background-color: var(--color-theme-lighter);
}
.carousel .indicators .indicator-item.active {
  background-color: var(--color-theme);
}
